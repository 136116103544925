<template>
    <Defaultlayout :userprofile="userprofile">
      <PageSlider></PageSlider>
      <section class="about-us">
        <div class="container">
          <h1 class="page-heading">FAQ Ghar Bazaar</h1>
          <div class="description faq">                  
            <p><strong>How to place an order with Ghar Bazaar?</strong><br>
	To place an order with Ghar Bazaar, visit Ghar Bazaar.com or download our mobile app through Apple’s app store or Google’s play store. After that, follow the following easy steps to place your first order:</p>

                <ol>
                    <li>Add items to the cart.</li>
                    <li>Once you have added the items you want to shop for, click check out.</li>
                    <li>Enter a promo code if you have one.</li>
                    <li>Select your payment method and enter your payment details. Please make sure you mention the correct billing and delivery address.</li>
                    <li>Select an available delivery slot.</li>
                    <li>Review your order summary.</li>
                    <li>Confirm your order by clicking on place order.</li>
                    <li>Wait for the app to confirm your order and send you an acknowledgment email.</li>
                </ol>

                <p><strong>Do you offer free home delivery?</strong><br>
                    Yes, we offer free home delivery contingent on your state's minimum order value and destination distance requirement. To know more about Ghar Bazaar’s DFW metroplex delivery fee, visit IndiaBazaarDFW.com/HomeDelivery, and for Atlanta Metroplex’s delivery fee&nbsp;<a href="http://www.indiaco.com/GharBazaar" target="_blank">www.indiaco.com/GharBazaar</a>.</p>

                <p><strong>Do I need to be present at home to receive a Ghar Bazaar order?</strong><br>
                    A customer only needs to be present to receive a delivery if the order they have placed is worth more than $100, as our drivers only hand over/ drop off the package after a signature is received. This step is crucial for verification purposes and for us to make sure your valuable order is delivered to the right hands. If the order is worth less than $100, then a customer doesn't need to be present at the time of the delivery, as the package will be dropped at its destination address’s doorstep without needing any signature.</p>

                <p><strong>Why do I need to submit ID proof?</strong><br>
                    Since all the information is submitted online, we need to verify the identity of a shopper to avoid fraud and misuse of payment information. To do this, all customers must provide id proof with orders worth $150 or more. </p>

                <p><strong>When will I receive my order?</strong><br>
                    Each order gets delivered on the date selected by the customer during the time of order placement. Please note that certain unforeseen circumstances like bad weather, disturbance in the supply chain, and other reasons may cause delays in order fulfillment.</p>

                <p style="margin-bottom: 0;"><strong>Who can I contact if I have an issue with my order/refund/replacement/ or placing an order?</strong><br>
                    After receiving the delivery of an order for any return/ refund/credit/service related concerns email, at <a href="mailto:support@gharbazaar.com">support@gharbazaar.com</a>, & for general inquiries/ to check the order status call:</p>
                    <p style="margin-bottom: 0;">Dallas Metroplex- <a href="tel:(214) 872-1038">(214) 872-1038</a></p >
                    <p>Atlanta Metroplex - <a href="tel:(470) 545-0064">(470) 545-0064</a></p >
                
                <p><strong>Can I return a product?</strong><br>
                    We have a no-return & no exchange policy on fresh fruits, vegetables, frozen items, dairy, and other perishable products. But, if you have received an incorrect or damaged product due to the Ghar Bazaar team’s mistake, then a return or exchange can be made contingent that the issue gets reported in a given time frame from the time order gets dropped at the recipient's doorstep, which is within 24 hours for fresh produce and frozen items, and between 24-48 hours for other products. Any damages/quality issues reported beyond that period will not qualify for a refund or exchange. Please note that some items you order might be unavailable or have quantity restrictions, in that case, the order will be adjusted to what we have available. Contact support to notify our team via email at <a href="mailto:support@gharbazaar.com">support@gharbazaar.com</a> or by filling out the contact us form available in the app.</p>

          </div>
        </div>
      </section>
    </Defaultlayout>
  </template>
  <script>
  import Defaultlayout from "../../layouts/Defaultlayout";
  import PageSlider from "../pages/PageSlider";
  export default {
    name: "Faq",
    components: {
      Defaultlayout,
      PageSlider,
    },
    data() {
      return {
        loading: true,
        userprofile: null,
      };
    },
    mounted() {
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }
  },
  };
  </script>